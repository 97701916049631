<template>
  <vs-popup
    classContent="popup-example"
    :title="lang.entities.edit.popUpTitle[languageSelected]"
    :active.sync="activePopup"
  >
    <div>
      <vs-input
        class="mr-3 mt-2 w-full mb-base"
        v-model="newName"
        :label="lang.entities.edit.name[languageSelected]"
        :disabled="saving"
      ></vs-input>
      <vs-divider />
      <a
        class="float-right mb-2"
        style="cursor: pointer;"
        @click="addValue"
        :disabled="saving"
      >
        {{ lang.entities.edit.addValue[languageSelected] }}&nbsp;&nbsp;
      </a>
      <vs-table
        :data="newValues"
        sorted
        class="mt-2"
        :no-data-text="lang.general.noDataText[languageSelected]"
      >
        <template slot="thead">
          <vs-th sort-key="value">
            {{ lang.entities.edit.valuesAndSynonyms[languageSelected] }}
          </vs-th>
        </template>
        <template slot-scope="{ data }">
          <vs-tr
            :data="tr"
            :key="indextr"
            v-for="(tr, indextr) in data"
            class="m-0 p-0"
          >
            <vs-td
              :data="data[indextr].synonyms"
              class="mt-4"
              style="padding-right: 33px"
            >
              <div class="vx-row mb-4">
                <vs-col class="w-2/3">
                  <vs-input
                    class="mt-0 mb-4 text-primary"
                    v-model="data[indextr].value"
                    :label="lang.entities.edit.value[languageSelected]"
                    :disabled="saving"
                  ></vs-input>
                </vs-col>
                <vs-col class="w-1/3">
                  <feather-icon
                    icon="Trash2Icon"
                    class="float-right mt-6 pt-3 cursor-pointer text-danger"
                    svgClasses="h-5 w-5"
                    @click="removeValue(indextr)"
                    :disabled="saving"
                  />
                </vs-col>
              </div>

              <vs-divider class="mt-4" />
              <vs-input
                class="w-full mt-4 mb-4"
                v-model="newValuesInputs[indextr].synonym"
                :label="lang.entities.edit.addSynonym[languageSelected]"
                @keypress.enter="addSynonym(indextr)"
                :disabled="saving"
              ></vs-input>
              <div class="vx-row mb-4">
                <vs-col>
                  <vs-chip
                    @click="removeSynonym(indextr, index)"
                    class="mr-2 mt-3 text-dark"
                    color="secundary"
                    v-for="(synonym, index) in data[indextr].synonyms"
                    :key="index"
                    closable
                    :disabled="saving"
                    >{{ synonym }}</vs-chip
                  >
                </vs-col>
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>

      <vs-divider />
      <vs-button
        class="float-right"
        icon-pack="feather"
        icon="icon-save"
        @click="save"
        :disabled="saving"
      >
        {{ this.lang.entities.edit.saveText[this.languageSelected] }}
      </vs-button>
      <vs-button
        type="flat"
        class="float-right mr-4"
        icon-pack="feather"
        icon="icon-x"
        color="danger"
        @click="cancel"
        :disabled="saving"
      >
        {{ this.lang.entities.edit.cancelText[this.languageSelected] }}
      </vs-button>
    </div>
  </vs-popup>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex'
import BotMakerService from '../../../services/botMaker.service'
import { errorHandler } from '../../../services/ErrorHandler'

export default {
  name: 'EditEntity',
  props: {
    entity: Object
  },
  data() {
    return {
      activePopup: false,
      saving: false,

      newName: '',
      newValues: [],
      newValuesInputs: [],

      chatMsgInput: null,

      selected: null
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('botMaker', ['bot', 'entities'])
  },
  watch: {},
  methods: {
    ...mapMutations('botMaker', ['UPDATE_ENTITY']),
    removeValue(indextr) {
      this.newValues.splice(indextr, 1)
      this.newValuesInputs.splice(indextr, 1)
    },
    removeSynonym(indextr, index) {
      if (!this.saving) {
        this.newValues[indextr].synonyms.splice(index, 1)
      }
    },
    addSynonym(index) {
      if (
        this.newValues[index].synonyms
          .map(element => element.toLowerCase())
          .indexOf(this.newValuesInputs[index].synonym.trim().toLowerCase()) >
        -1
      ) {
        // this.newValuesInputs[index].synonym = ''
        this.$vs.notify({
            text: this.lang.errors.errorDescription.synonyms[this.languageSelected],
            color: 'warning',
          })
        return
      }
      this.newValues[index].synonyms.push(this.newValuesInputs[index].synonym.trim())
      this.newValuesInputs[index].synonym = ''
    },
    openPopup() {
      this.activePopup = true
      this.updateFields(this.entity)
    },
    addValue() {
      this.newValues = [{ value: '', synonyms: [] }].concat(this.newValues)
      this.newValuesInputs = [{ value: '', synonym: '' }].concat(
        this.newValuesInputs
      )
    },
    updateFields(newVal) {
      this.newName = newVal.name
      this.newValues = newVal.values.map(element => {
        return {
          value: element.value,
          synonyms: JSON.parse(JSON.stringify(element.synonyms)).sort(
            (a, b) => {
              if (a < b) {
                return -1
              }
              if (a > b) {
                return 1
              }
              return 0
            }
          )
        }
      })
      this.newValuesInputs = this.newValues.map(element => ({
        value: element.value,
        synonym: ''
      }))
    },
    cancel() {
      this.activePopup = false
    },
    async save() {
      if (!this.newName || this.newName.trim().length === 0) {
        return this.$vs.notify({
          title: this.lang.entities.validationErrors.invalidName.title[
            this.languageSelected
          ],
          text: this.lang.entities.validationErrors.invalidName.text[
            this.languageSelected
          ],
          color: 'warning',
          position: 'top-right'
        })
      }
      if (this.newValues.length === 0) {
        return this.$vs.notify({
          title: this.lang.entities.validationErrors.notValues.title[
            this.languageSelected
          ],
          text: this.lang.entities.validationErrors.notValues.text[
            this.languageSelected
          ],
          color: 'warning',
          position: 'top-right'
        })
      }
      const invalidValue = this.newValues.some(
        element => !element.value || element.value.trim().length === 0
      )
      if (invalidValue) {
        return this.$vs.notify({
          title: this.lang.entities.validationErrors.invalidValue.title[
            this.languageSelected
          ],
          text: this.lang.entities.validationErrors.invalidValue.text[
            this.languageSelected
          ],
          color: 'warning',
          position: 'top-right'
        })
      }
      this.saving = true
      try {
        const updatedEntity = {
          name: this.newName,
          values: this.newValues
        }
        const result = await BotMakerService.updateEntity(
          this.entity._id,
          updatedEntity
        )
        this.$vs.notify({
          title: this.lang.entities.edit.success.title[this.languageSelected],
          text: this.lang.entities.edit.success.text[this.languageSelected],
          color: 'success',
          position: 'top-right'
        })
        this.UPDATE_ENTITY(result.data)
        this.cancel()
      } catch (error) {
        if (error && error.response && error.response.data[0]) {
          errorHandler(error.response.data[0])
        }
      }
      this.saving = false
    }
  }
}
</script>
